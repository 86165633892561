.DeviceCard {
  margin-top: 1em;
  min-height: 125px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  padding-top: 11px;
  /* filter: drop-shadow(10px 10px 20px #888888); */
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
}

.DeviceCard p {
  color: #666;
}