.DeviceCard {
  /* margin-top: 1em; */
  /* margin: auto; */
  min-height: 125px;
  text-align: center;
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.DeviceCard p {
  color: #666;
}